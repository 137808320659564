import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
//how do i make the notification been read here
const Notification = () => {
  return (
    <>
      <Alert variant="primary">
        need to add the Notification
      </Alert>

      <Stack direction="horizontal">
        <Badge pill bg="light" text="dark">
        Accept
        </Badge>
        <Badge pill bg="dark">
        Deny
        </Badge>
      </Stack>
    </>
  );
}

export default Notification;
