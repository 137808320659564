import { Link } from "react-router-dom";
import { CtaImg } from "components/imagePath";
import Book from 'assets/images/book.svg'

const CTA = () => {
  return (  
    <section className="cta-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="cta-content">
              <h3>
                Earn Cash by <span>Selling</span> <br />
                or Find Your Next Literary Treasure
              </h3>
              <p>
                Explore a world of possibilities! Sell your pre-loved books and
                make some extra cash. Alternatively, browse our marketplace to
                find books that spark your interest and connect with fellow
                readers.
              </p>
              <div className="cta-btn">
                <Link to="/books/new" className="btn-primary postad-btn">
                  Sell Your Books
                </Link>
                <Link to="/books" className="browse-btn">
                  Browse Books
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="cta-img">
              <img src={Book} className="img-fluid" alt="CTA" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
 
export default CTA;
