import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { signIn } from '../../api';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { setToken } from 'utils/storage';
import { Formik } from 'formik';

const SignInForm = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    const user = {
      email: values.email,
      password: values.password,
    };

    try {
      const response = await signIn(user);
      setAuth({
        isLoggedIn: true,
        user: response.user,
      });
      setToken(response.token);
      toast.success('You have logged in successfully', {
        position: 'top-right',
      });
      navigate('/');
    } catch (error) {
      toast.error('Login failed. Please try again.', {
        position: 'top-right',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3 justify-content-center">
            <Col md={12}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="text-center mt-3">
                <button type="submit" className="btn btn-primary w-100 login-btn" style={{ width: '50%', fontSize: '14px' }}>
                  Sign In
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
