import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/theme.css'

const Mainlayout = () => {
    return <div className="main-wrapper">

        < Header />
        < Outlet />
        < Footer />


    </div>;
};
export default Mainlayout;

