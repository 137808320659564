import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Carousel, ListGroup, Button } from "react-bootstrap";
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { fetchBookAPI, orderRequestAPI } from "./api";
import { generateImageURL } from "helpers/image";
import Loader from "components/Loader";

const BookDetails = () => {
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const { bookId } = useParams();
  const { user } = useAuth();

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    setLoading(true);
    try {
      const response = await fetchBookAPI(bookId);
      setBook(response.data);
    } catch (error) {
      console.error("Error fetching book details:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader isLoading={loading}/>
  }

  if (!book) {
    return <div>Book not found</div>;
  }

  const canEditBook = user?._id === book.userId;

  const orderRequest = async () => {
    setLoading(true);
    try {
      const response = await orderRequestAPI({ bookId, type: "purchase" });
      // Update the state with the response if needed
      // setBook(response.data);
    } catch (error) {
      console.error("Error sending order request:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="details-description" style={{ marginTop: "90px" }}>
        <div className="container">
          <div className="about-details">
            <div className="about-headings">
              <div className="author-img">
                <img src={generateImageURL(book.image)} alt="authorimg" />
              </div>
              <div className="authordetails">
                <h5>{book.name}</h5>
                <p>by {book.author}</p>
              </div>
            </div>
            <div className="rate-details">
              <h2>₹{book.sellingPrice}</h2>
              <p>
                <s>₹{book.actualPrice}</s>
              </p>
            </div>
          </div>
          <div className="descriptionlinks">
            <div className="row">
              <div className="col-lg-6">
                <ul></ul>
              </div>
              <div className="col-lg-6">
                <div className="callnow">
                  <Button onClick={orderRequest} className="d-inline">
                    Purchase Now
                  </Button>{" "}
                  {canEditBook && (
                    <Button
                      as={Link}
                      to={`/books/${bookId}/edit`}
                      className="d-inline"
                    >
                      Edit Book
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="details-main-wrapper">
        <div className="container">
          <div className="row">
          <div className="col-lg-6 theiaStickySidebar">
            <div className="rightsidebar">
        <div className="card">
          <h4>
            Details
            {/* <img src={details_icon} alt="details-icon" /> Details */}
          </h4>
          <ul>
            <li>
              Publisher <span>{book.publisher}</span>
            </li>
            <li>
              Cover Type <span>{book.coverType}</span>
            </li>
            <li>
              Condition <span>{book.condition}</span>
            </li>
            <li>
              Genre <span>{book.genre}</span>
            </li>
            <li>
            Prefered Book <span>{book.preferredbook}</span>
            </li>
            <li>
              Prefered Type<span>{book.preferredType}</span>
            </li>
            
          </ul>
        </div>
      </div>
            </div>
            <div className="col-lg-6">
              <div className="card ">
                <div className="card-header">
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                  <h4>Description</h4>
                </div>
                <div className="card-body">{book.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </>
    // <div className="container mt-4">
    //   <div className="row">
    //     <div className="col-lg-8">
    //       <Carousel fade controls={false} interval={5000}>
    //         {book.images && book.images.length > 0 ? (
    //           book.images.map((image, index) => (
    //             <Carousel.Item key={index}>
    //               <img
    //                 className="d-block w-100"
    //                 src={image.url}
    //                 alt={`Slide ${index + 1}`}
    //               />
    //               <Carousel.Caption>
    //                 <h3>{image.title}</h3>
    //                 <p>{image.description}</p>
    //               </Carousel.Caption>
    //             </Carousel.Item>
    //           ))
    //         ) : (
    //           <Carousel.Item>
    //             <img
    //               className="d-block w-100"
    //               src="https://dummyimage.com/640x360/fff/aaa"
    //               alt="Placeholder"
    //             />
    //             <Carousel.Caption>
    //               <h3>No Images Available</h3>
    //               <p>Sorry, no images found for this book.</p>
    //             </Carousel.Caption>
    //           </Carousel.Item>
    //         )}
    //       </Carousel>
    //     </div>

    //     <div className="col-lg-4">
    //       <ListGroup>
    //         <ListGroup.Item>
    //           <strong>Name:</strong> {book.name || "N/A"}
    //         </ListGroup.Item>
    //         <ListGroup.Item>
    //           <strong>Description:</strong> {book.description || "N/A"}
    //         </ListGroup.Item>
    //         <ListGroup.Item>
    //           <strong>Author:</strong> {book.author || "N/A"}
    //         </ListGroup.Item>
    //         <ListGroup.Item>
    //           <strong>MRP:</strong> {book.actualPrice || "N/A"}
    //         </ListGroup.Item>
    //         <ListGroup.Item>
    //           <strong>Genre:</strong> {book.genre || "N/A"}
    //         </ListGroup.Item>
    //         <ListGroup.Item>
    //           <strong>Selling Price:</strong> {book.sellingPrice || "N/A"}
    //         </ListGroup.Item>
    //       </ListGroup>

    //       <div className="text-right mt-3">
    // <Button onClick={orderRequest} variant="success"  >Purchase Now</Button>{' '}
    // {canEditBook && (
    //   <Button variant="warning" as={Link} to={`/books/${bookId}/edit`}>Edit Book</Button>
    // )}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BookDetails;
