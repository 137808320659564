export const convertJsonToFormData = (jsonObject) => {
    const formData = new FormData();
  
    Object.entries(jsonObject).forEach(([key, value]) => {
      // If the value is a file, append it directly to FormData
      if (value instanceof File) {
        formData.append(key, value);
      } else if (Array.isArray(value)) {
        // If the value is an array, append each element separately
        value.forEach((element, index) => {
          formData.append(`${key}[${index}]`, element);
        });
      } else {
        // Otherwise, append the key-value pair
        formData.append(key, value);
      }
    });
  
    return formData;
  }