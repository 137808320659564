import {
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  Feature9,
  ProfileAvatar02,
  ProfileAvatar04,
  ProfileAvatar05,
  ProfileAvatar06,
} from "components/imagePath";
import { Link } from "react-router-dom";
import { MdOutlineCategory } from "react-icons/md";
import { GrStatusInfo } from "react-icons/gr";
import { PiPencilCircle } from "react-icons/pi";
import { BsJournalBookmark } from "react-icons/bs";
import { generateImageURL } from "helpers/image";


const BookCard = ({ book = {} }) => {
  return (
    <div className="card aos" data-aos="fade-up">
      <div className="blog-widget">
        <div className="blog-img">
          <Link to={`/books/${book._id}`}>
            <img src={generateImageURL(book.image) ?? Feature9} className="img-fluid" alt="blog-img" />
          </Link>
          <div className="fav-item">
            <span className="Featured-text">Trending</span>
          </div>
        </div>
        <div className="bloglist-content">
          <div className="card-body">
            <div className="blogfeaturelink">
              <div className="grid-author">
                <img src={ProfileAvatar02} alt="author" />
              </div>
              <div className="blog-features">
                <Link to="#">
                  <span className="text-capitalize">
                    {" "}
                    <MdOutlineCategory
                      size={18}
                      style={{ display: "inline" }}
                      className="me-1"
                    />
                    {book.genre}
                  </span>
                </Link>
              </div>
              <div className="blog-author text-end">
                <span className="text-capitalize">
                  <GrStatusInfo className="d-inline me-2 " size={18} />{" "}
                  {book.condition}
                </span>
              </div>
            </div>
            <h6 className="text-capitalize">
              <Link to={`/books/${book._id}`}>{book.name}</Link>
            </h6>
            <div className="blog-location-details">
              <div className="location-info">
              <PiPencilCircle className="d-online me-1" size={18}/>
                {book.author}
              </div>
              <div className="location-info">
              <BsJournalBookmark className="d-online me-1" size={18}/> {book.coverType}
              </div>
            </div>
            <div className="amount-details">
              <div className="amount">
                <span className="validrate">₹{book.sellingPrice}</span>
                <span>₹{book.actualPrice}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookCard;
