import { Row, Col } from "react-bootstrap";
import SignUpForm from "./components/SignUpFrom";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Create an Account</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    SignUp
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="login-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5 mx-auto">
              <div className="login-wrap register-form">
                <div className="login-header">
                  <h3>Create an Account</h3>
                  <p>
                    Lets start with <span>One Page</span>
                  </p>
                </div>
                <SignUpForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUp;
