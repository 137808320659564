import { Row, Col } from 'react-bootstrap'
import SignInForm from "./components/SignInForm"
import { Link } from 'react-router-dom'


const SignIn = () => {
    return (
        <>
        <div className="breadcrumb-bar">
        <div className="container">
            <div className="row align-items-center text-center">
                <div className="col-md-12 col-12">
                    <h2 className="breadcrumb-title">Create an Account</h2>
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                New Book
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div className="login-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 mx-auto">
                            <div className="login-wrap register-form">
                                <div className="login-header">
                                    <h3>Welcome Back</h3>
                                    <p>
                                        Please enter your details
                                    </p>
                                </div>
                            <SignInForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
    )
}
export default SignIn
