import React, { useRef } from "react";
import { Quotes } from "components/imagePath";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";

import MaleAvatar from 'assets/images/male-avatar.svg'
import FeMaleAvatar from 'assets/images/female-avatar.svg'


const Testimonial = () => {
  const slider = useRef();
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <section className="testimonials-section">
      <div className="row">
        <div className="col-lg-5">
          <div className="testimonial-heading d-flex l70">
            <h4>
              {" "}
              Reader <br /> Testimonials
            </h4>
            <img src={Quotes} alt="quotes" />
          </div>
        </div>
        <div className="col-lg-7">
          <div className="rightimg"></div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="testimonials-slidersection">
            <div className="owl-nav mynav1">
              <button
                type="button"
                role="presentation"
                className="owl-prev"
                onClick={() => slider?.current?.slickPrev()}
              >
                <RiArrowLeftSLine size={28} style={{margin: '0 auto'}}/>
              </button>
              <button
                type="button"
                role="presentation"
                className="owl-next"
                onClick={() => slider?.current?.slickNext()}
              >
                <RiArrowRightSLine size={28} style={{margin: '0 auto'}}/>
              </button>
            </div>
            <div className="testi-slider">
              <Slider ref={slider} {...settings}>
                <div className="testimonial-info">
                  <div className="testimonialslider-heading d-flex">
                    <div className="testi-img">
                      <img
                        src={FeMaleAvatar}
                        className="img-fluid"
                        alt="testi-img"
                        style={{height: '90px'}}
                      />
                    </div>
                    <div className="testi-author">
                      <h6>Book Lover</h6>
                      <p>Passionate Reader</p>
                    </div>
                  </div>
                  <div className="testimonialslider-content">
                    <p>
                      "OnePage has transformed my reading experience. I've
                      discovered hidden gems, sold books I've cherished, and
                      connected with fellow book enthusiasts. Truly a literary
                      haven!"
                    </p>
                  </div>
                </div>
                <div className="testimonial-info">
                  <div className="testimonialslider-heading d-flex">
                    <div className="testi-img">
                      <img
                        src={MaleAvatar}
                        className="img-fluid"
                        alt="testi-img"
                        style={{height: '90px'}}
                      />
                    </div>
                    <div className="testi-author">
                      <h6>Erik</h6>
                      <p>Dedicated Bibliophile</p>
                    </div>
                  </div>
                  <div className="testimonialslider-content">
                    <p>
                      "Finding and exchanging books has never been easier.
                      OnePage connects book lovers, making it a joy to share and
                      discover new stories. A must-have for any avid reader!"
                    </p>
                  </div>
                </div>
                <div className="testimonial-info">
                  <div className="testimonialslider-heading d-flex">
                    <div className="testi-img">
                      <img
                        src={MaleAvatar}
                        className="img-fluid"
                        alt="testi-img"
                        style={{height: '90px'}}
                      />
                    </div>
                    <div className="testi-author">
                      <h6>Book Explorer</h6>
                      <p>Adventurous Reader</p>
                    </div>
                  </div>
                  <div className="testimonialslider-content">
                    <p>
                      "OnePage has opened up a world of literary exploration for
                      me. I've stumbled upon unique editions and connected with
                      book lovers who share my eclectic taste. It's a community
                      that celebrates the joy of reading!"
                    </p>
                  </div>
                </div>

                <div className="testimonial-info">
                  <div className="testimonialslider-heading d-flex">
                    <div className="testi-img">
                      <img
                        src={FeMaleAvatar}
                        className="img-fluid"
                        alt="testi-img"
                        style={{height: '90px'}}
                      />
                    </div>
                    <div className="testi-author">
                      <h6>Storyteller</h6>
                      <p>Aspiring Author</p>
                    </div>
                  </div>
                  <div className="testimonialslider-content">
                    <p>
                      "As someone passionate about storytelling, OnePage has
                      been a treasure trove. It's not just a platform to buy and
                      sell books, but a vibrant space where stories come to life
                      through shared experiences and conversations. A true haven
                      for book enthusiasts!"
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
