import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';
import useAuth from "hooks/useAuth";


const Profile = () => {
  const { user } = useAuth();


  if (!user) {
    return <div>User not found</div>;
  }
  return (
    <MDBContainer className="py-5">
      <MDBRow className="justify-content-center">
        <MDBCol lg="6" className="mb-4 mb-lg-0">
          <MDBCard className="mb-3">
            <MDBRow className="g-0">
              <MDBCol md="4" className="gradient-custom text-center text-white">
                <MDBCardImage src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                  alt="Avatar" className="my-5" style={{ width: '80px' }} fluid />
                <MDBTypography tag="h5">{user.username}</MDBTypography>
                <MDBCardText>{user.firstName} {user.lastName}</MDBCardText>
                <MDBCardText>{user.dateOfBirth}</MDBCardText>
                <MDBCardText>{user.gender}</MDBCardText>
                <MDBCardText>{user.phoneNumber}</MDBCardText>
                <MDBIcon far icon="edit mb-5" />
              </MDBCol>
              <MDBCol md="8">
                <MDBCardBody className="p-4">
                  <MDBTypography tag="h6">Information</MDBTypography>
                  <hr className="mt-0 mb-4" />
                  <MDBRow className="pt-1">
                    <MDBCol size="8" className="mb-3">
                    
                      
                      <MDBTypography tag="h7">Username</MDBTypography>
                      <MDBCardText className="text-muted">{user.username}</MDBCardText>
                      <MDBTypography tag="h7">Email</MDBTypography>
                      <MDBCardText className="text-muted">{user.email}</MDBCardText>
                      <MDBTypography tag="h7">Phone Number</MDBTypography>
                      <MDBCardText className="text-muted">{user.phoneNumber}</MDBCardText>
                    </MDBCol>
                  </MDBRow>

                  <MDBTypography tag="h6">Additional Information</MDBTypography>
                  <hr className="mt-0 mb-4" />
                  <MDBRow className="pt-1">
                  <MDBTypography tag="h7">First Name</MDBTypography>
                  <MDBCardText className="text-muted">{user.firstName}</MDBCardText>
                      <MDBTypography tag="h7">Last Name</MDBTypography>
                      <MDBCardText className="text-muted">{user.lastName}</MDBCardText>
                  </MDBRow>

                  <div className="d-flex justify-content-start">
                    <a href="#!"><MDBIcon fab icon="facebook me-3" size="lg" /></a>
                    <a href="#!"><MDBIcon fab icon="twitter me-3" size="lg" /></a>
                    <a href="#!"><MDBIcon fab icon="instagram me-3" size="lg" /></a>
                  </div>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Profile;
