import { getToken } from "utils/storage";

export const signUpAPI = async (user) => {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/users`,{
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': "application/json"
            }
        });
        const jsonResponse = await response.json();
        return jsonResponse.data;
    };



    export const signIn = async (user) => {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/users/login`,{
            method: "POST",
            body: JSON.stringify(user),
            headers: {
                'Content-Type': "application/json"
            }
        });
        const jsonResponse = await response.json();
        return jsonResponse.data;
    };


    export const getCurrentUserAPI = async (user) => {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/users/current-user`,{
            headers: 
            {
                "Content-Type":"application/json",
                "Authorization": `Bearer ${getToken()}`
            },
        });
        const jsonResponse = await response.json();
        return jsonResponse.data;
    };
  
    export const getNotificationAPI = async (user) => {
        const response = await fetch(`${process.env.REACT_APP_API_HOST}/notifications`,{
        //what to do here
        });
        const jsonResponse = await response.json();
        return jsonResponse.data;
    }
 