import MoonLoader from "react-spinners/MoonLoader";

const Loader = ({isLoading, override}) => {
  return ( 
    <MoonLoader
        color="#c10037"
        loading={isLoading}
        cssOverride={{
          position: 'absolute',
          top: '40%',
          left: '50%'
        }}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
   );
}
 
export default Loader;