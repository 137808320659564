import React from 'react';
import { toast } from 'react-toastify';
import { signUpAPI } from '../../api';
import { Formik, Field, Form, } from 'formik';
import { Button } from 'react-bootstrap';

const SignUpForm = () => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await signUpAPI(values);
      toast.success('Sign up successful!', {
        position: 'top-right',
      });
    } catch (error) {
      // Handle sign-up failure
      toast.error('Sign up failed. Please try again.', {
        position: 'top-right',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        dateOfBirth: '',
        phoneNumber: '',
        gender: '',
      }}
      onSubmit={handleSubmit}
    >
      <Form className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <Field type="text" className="form-control" id="firstName" name="firstName" required />
            </div>

            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <Field type="text" className="form-control" id="lastName" name="lastName" required />
            </div>

            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <Field type="text" className="form-control" id="username" name="username" required />
            </div>

            <div className="mb-3">
              <label htmlFor="gender" className="form-label">
                Gender
              </label>
              <Field as="select" className="form-control" id="gender" name="gender" required>
                <option value="" disabled>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Field>
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <Field
                type="email"
                className="form-control"
                id="email"
                name="email"
                required
                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label">
                Phone Number
              </label>
              <Field type="text" className="form-control" id="phoneNumber" name="phoneNumber" required />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <Field type="password" className="form-control" id="password" name="password" required />
            </div>

            <div className="mb-3">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth
              </label>
              <Field type="date" className="form-control" id="dateOfBirth" name="dateOfBirth" required />
            </div>

            <Button type="submit" className="btn btn-dark" style={{ width: '100%' }}>
              Sign Up
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default SignUpForm;
