import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Row, Col } from "react-bootstrap";
import BookForm from "./components/BookForm";
import { updateBookAPI, fetchBookAPI } from "./api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Loader from "components/Loader";

const EditBook = () => {
  const [book, setBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const { bookId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBooks();
  }, [bookId]);

  const fetchBooks = async () => {
    setLoading(true);
    try {
      const response = await fetchBookAPI(bookId);
      setBook(response.data);
    } catch (error) {
      console.error("Error fetching book details:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader isLoading={loading}/>
  }

  const onSubmitBook = async (updatedBook) => {
    try {
      const response = await updateBookAPI(bookId, updatedBook);
      if (response.success) {
        navigate("/");
        toast.success("Book updated successfully!");
      } else {
        toast.error("Failed to update the book, please try again!");
      }
    } catch (error) {
      toast.error("Failed to update the book, please try again!");
    }
  };

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Edit book</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Book
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-content">
        <div className="container">
          <BookForm book={book} onSubmit={onSubmitBook} />
        </div>
      </div>
    </>
  );
};

export default EditBook;
