import { useState, useEffect } from "react";
import BookCard from 'pages/Books/components/BookCard'
import { Container, Row, Col } from "react-bootstrap";
import HeroSection from "components/Hero";
import Banner from "./components/Banner"
import Trending from './components/Trending'
import CTA from "./components/CTA";
import Testimonial from "./components/Testimonials";


const Home = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    fetchBooks()
  }, []);


  const fetchBooks = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/books/featured`);
    const jsonResponse = await response.json();
    setBooks(jsonResponse.data);

  }
  return (
    <Container className="mt-5" fluid>
      <Banner/>
      <Trending books={books}/>
      <CTA/>
      <Testimonial/>
    </Container>

  )
};

export default Home;
