import { useState, useEffect } from "react";
import BookCard from "pages/Books/components/Card";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Books = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/books`);
    const jsonResponse = await response.json();
    setBooks(jsonResponse.data);
  };
  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Books</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link href="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Books
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-content">
        <div className="container">
        <div className="bookmarks-content grid-view featured-slider">
        <div className="row">
          {books.map((book) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-6 ">
                <BookCard book={book} />
              </div>
            );
          })}
          </div>
          </div>
        </div>
      </div>
    </>
    // <Container className="mt-5" fluid>
    //   <Row>
    //     {books.map((book) => {
    //       return (
    //         <Col className="mb-2">
    //           <BookCard book={book} />

    //         </Col>
    //       );
    //     })}
    //   </Row>

    // </Container>
  );
};

export default Books;
