import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";


import useAuth from "hooks/useAuth";
import { toast } from "react-toastify";
import { setToken } from "utils/storage";
import { profile_img } from "components/imagePath";

const Header = ({ parms }) => {
  const [drops,setDrops]=useState(false);
  const { isLoggedIn, setAuth, user } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");

  const logout = () => {
    setAuth({
      isLoggedIn: false,
      user: null,
    });
    toast.success("You have logged out successfully", {
      position: "top-right",
    });
    setToken("");
  };

  const handleSearch = () => {
    //what are the things to be done here
    console.log("Search query:", searchQuery);
  };
  console.log(parms, "header");

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  return (
    <header className="header" onClick={(value) => toggleMobileMenu()}>
      <div className="container">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <Link id="mobile_btn" to="#" onClick={() => onHandleMobileMenu()}>
              <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </Link>
            <Link to="/" className="navbar-brand logo">
              {/* <img src={LogoSvg} className="img-fluid" alt="Logo" /> */}
              OnePage
            </Link>
          </div>
          <div className="main-menu-wrapper">
            <div className="menu-header">
              <Link to="/" className="menu-logo">
                {/* <img src={LogoSvg} className="img-fluid" alt="Logo" /> */}
                OnePage
              </Link>
              <Link
                id="menu_close"
                className="menu-close"
                to="#"
                onClick={() => onhandleCloseMenu()}
              >
                {" "}
                <MdOutlineClose />

              </Link>
            </div>
            <ul className="main-nav">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/books">Books</NavLink>
              </li>
              <li className="login-link">
                <Link to="/signup">Sign Up</Link>
              </li>
              <li className="login-link">
                <Link to="/signin">Sign In</Link>
              </li>
            </ul>
          </div>
          <ul className="nav header-navbar-rht">
            {!isLoggedIn ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link header-reg" to="/signup">
                    Sign Up
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link header-login" to="/signin">
                    {" "}
                    Sign In
                  </Link>
                </li>
              </>
            ) : (
              <ul className="nav header-navbar-rht">
                            <li className="nav-item">
                                <Link
                                    className="nav-link header-login add-listing"
                                    to="/books/new"
                                >
                                    <i className="fa-solid fa-plus" /> Add a Book
                                </Link>

                              
                            </li>
                            <li className="nav-item dropdown has-arrow logged-item">
                                <Link
                                    to="#"
                                    className={`${drops===true ?"dropdown-toggle profile-userlink show " :"dropdown-toggle profile-userlink"}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={()=>setDrops(!drops)}
                                    // className={`${change1===true ? 'dropdown-menu dropdown-menu-end show' : "dropdown-menu dropdown-menu-end"}`}
                                >
                                    <img src={profile_img} alt="" />
                                    <span>{user.username}</span>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end">
                                    {/* <Link className="dropdown-item" to="/dashboard">
                                        Dashboard
                                    </Link>
                                    <Link className="dropdown-item" to="/profile">
                                        Profile Settings
                                    </Link> */}
                                    <Link className="dropdown-item" onClick={logout}>
                                        Logout
                                    </Link>
                                </div>
                            </li>
                        </ul>
                
              // <li className="nav-item">
              //   <Button variant="danger" className="nav-link header-login" onClick={logout}>
              //     Logout
              //   </Button>
              // </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
