import {
  createBrowserRouter,
  RouterProvider,    //componet
} from "react-router-dom";

import ProtetedRoute from "./ProtectedRoute";

import Home from "../pages/Home"
import Books from "../pages/Books"
import Main from "layout/Main"
import BookDetails from "pages/Books/BookDetails";
import SignUp from "pages/Users/SignUp";
import SignIn from "pages/Users/SignIn";
import 'react-toastify/dist/ReactToastify.css';
import NewBook from "pages/Books/NewBook";
import EditBook from "pages/Books/EditBook";
import orderRequest from "../pages/Books/BookDetails"
import ExchangeBookForm from "pages/Books/components/ExchangeBookForm";
import Profile from "../pages/Users/Profile/Profile";
import Notification from "../pages/Notification/Notification";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "/books",
        element: <Books />
      },
      {
        path: "/books/:bookId",
        element: <BookDetails />
      },
      {
        path: "/signup",
        element: <SignUp />
      },
      {
        path: "/signin",
        element: <SignIn />
      },
      {
        path: "/books/new",
        element: <ProtetedRoute element={NewBook} />
      },
      {
        path: "/books/:bookId/edit",
        element: <ProtetedRoute element={EditBook} />
      },
      {
        path: "/books/bookId",
        element: <ProtetedRoute element={orderRequest} />
      },
      {
        path: "/profile",
        element: <ProtetedRoute element={Profile} />
      },
      {
        path: "/exchange",
        element: <ProtetedRoute element={ExchangeBookForm} />
      },
      {
        path: "/notification",
        element: <Notification />
      },
    ]
  },

]);

const Router = () => (
  <RouterProvider router={router} />
)
export default Router;
