import { getToken } from "utils/storage";


export const createBookAPI = async (book) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/books`, {
        method: "POST",
        body: book,
        headers: {
            'Authorization': `Bearer ${getToken()}`,
        }
    });
    const jsonResponse = await response.json();
    return jsonResponse;
};

export const fetchBookAPI = async (bookId) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/books/${bookId}`);
    const jsonResponse = await response.json();
    return jsonResponse;
};

export const updateBookAPI = async (bookId, book) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/books/${bookId}`, {
        method: "PUT",
        body: book,
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            
        }
    });
    const jsonResponse = await response.json();
    return jsonResponse;
};
export const orderRequestAPI = async (body) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/order-requests`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': "application/json"
        }
    });

    console.log("orderRequestAPI response:", response);

    const jsonResponse = await response.json();
    return jsonResponse;
};
