import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

const ExchangeBookForm = ({ book, onSubmit }) => {
    const schema = yup.object().shape({
        name: yup.string().required('Name is required'),
        author: yup.string().required('Author is required'),
        publisher: yup.string(),
        coverType: yup.string().required('Cover type is required'),
        condition: yup.string().required('Condition is required'),
        actualPrice: yup.number().required('Actual price is required'),
        sellingPrice: yup.number().required('Selling price is required'),
        description: yup.string().required('Description is required'),
        genre: yup.string().required('Genre is required'),
        preferredBooks: yup
            .array()
            .of(yup.string().max(50))
            .max(5, 'Up to 5 preferred books allowed'),
        preferredType: yup.string().required('Preferred Type required')
    });

    return (
        <div>
            <h1 className="text-center mt-4">Details of the Book to be exchange</h1>
            <Formik
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    await onSubmit(values);
                }}
                initialValues={{
                    name: '',
                    author: '',
                    publisher: '',
                    coverType: '',
                    condition: '',
                    actualPrice: '',
                    sellingPrice: '',
                    description: '',
                    genre: '',
                    preferredBooks: [],
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} className="mx-auto mt-4" style={{ maxWidth: '800px' }}>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter the name of the book"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Author</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Name of the author"
                                        name="author"
                                        value={values.author}
                                        onChange={handleChange}
                                        isInvalid={!!errors.author}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.author}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom03">
                                    <Form.Label>Publisher</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Publisher"
                                        name="publisher"
                                        value={values.publisher}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom04">
                                    <Form.Label>Cover Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        required
                                        name="coverType"
                                        value={values.coverType}
                                        onChange={handleChange}
                                        isInvalid={!!errors.coverType}
                                    >
                                        <option value="" disabled>Select Cover Type</option>
                                        <option value="hard">Hard</option>
                                        <option value="soft">Soft</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.coverType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom05">
                                    <Form.Label>Condition</Form.Label>
                                    <Form.Control
                                        as="select"
                                        required
                                        name="condition"
                                        value={values.condition}
                                        onChange={handleChange}
                                        isInvalid={!!errors.condition}
                                    >
                                        <option value="" disabled>Select Condition</option>
                                        <option value="good">Good</option>
                                        <option value="excellent">Excellent</option>
                                        <option value="bad">Bad</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.condition}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom06">
                                    <Form.Label>Actual Price</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="MRP"
                                        name="actualPrice"
                                        value={values.actualPrice}
                                        onChange={handleChange}
                                        isInvalid={!!errors.actualPrice}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.actualPrice}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom07">
                                    <Form.Label>Selling Price</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Price to be sold"
                                        name="sellingPrice"
                                        value={values.sellingPrice}
                                        onChange={handleChange}
                                        isInvalid={!!errors.sellingPrice}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.sellingPrice}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom08">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                        isInvalid={!!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom09">
                                    <Form.Label>Genre</Form.Label>
                                    <Form.Control
                                        as="select"
                                        required
                                        name="genre"
                                        value={values.genre}
                                        onChange={handleChange}
                                        isInvalid={!!errors.genre}
                                    >
                                        <option value="" disabled>Select Genre</option>
                                        {[
                                            'fantasy',
                                            'sci-fi',
                                            'manga',
                                            'dystopian',
                                            'historical-fiction',
                                            'mystery-thriller',
                                            'horror',
                                            'adventure',
                                            'romance',
                                            'realistic-fiction',
                                            'steampunk',
                                            'cyberpunk',
                                            'post-apocalyptic',
                                            'alternate-history',
                                            'space-opera',
                                            'superhero',
                                            'crime',
                                            'western',
                                            'action',
                                            'comedy',
                                            'drama',
                                            'biography',
                                            'autobiography',
                                            'historical-non-fiction',
                                            'self-help',
                                            'young-adult',
                                            'childrens-fiction',
                                            'satire',
                                            'epic-poetry',
                                            'political-thriller',
                                            'magical-realism',
                                            'others',
                                            '',
                                        ].map((genre, index) => (
                                            <option key={index} value={genre}>
                                                {genre}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.genre}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group controlId="validationCustom10">
                                    <Form.Label>Preferred Books</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter preferred books (up to 5)"
                                        name="preferredBooks"
                                        value={values.preferredBooks.join(', ')}
                                        onChange={(e) => {
                                            const preferredBooks = e.target.value
                                                .split(',')
                                                .map((book) => book.trim())
                                                .filter(Boolean);
                                            handleChange({
                                                target: { name: 'preferredBooks', value: preferredBooks },
                                            });
                                        }}
                                        isInvalid={!!errors.preferredBooks}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.preferredBooks}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="validationCustom05">
                                    <Form.Label>Preferred Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        required
                                        name="preferredType"
                                        value={values.preferredType}
                                        onChange={handleChange}
                                        isInvalid={!!errors.preferredType}
                                    >
                                        <option value="" disabled>Select Preferred Type</option>
                                        <option value="Selling" disabled>Selling</option>
                                        <option value="Exchange">Exchange</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.preferredType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            <Col md={12} className="text-center mt-3">
                                <Button type="submit">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ExchangeBookForm;
