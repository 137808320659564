import {
  ArrowBanner,
  BannerArrow,
  Bannerbg,
  BannerEllipse,
  RightImg,
} from "components/imagePath";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";



import BannerImage from 'assets/images/banner.png'

const Banner = () => {
  return (
    <section className="banner-section">
      <div className="banner-circle">
        <img src={Bannerbg} className="img-fluid" alt="bannercircle" />
      </div>
      <div className="container">
        <div className="home-banner">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="section-search aos" data-aos="fade-up">
                <p className="explore-text">
                  {" "}
                  <span>Welcome to onepage!</span>
                </p>
                <img src={ArrowBanner} className="arrow-img" alt="arrow" />
                <h1>
                  Let us help you <br />
                  <span>Find, Buy</span> or <span>Exchange</span> Books
                </h1>
                <p>
                  A marketplace that revolutionizes the way book
                  enthusiasts buy, sell, and exchange used books. Seamlessly
                  connect with fellow readers, list your pre-loved books for
                  sale or trade, and explore a diverse marketplace to discover
                  new literary treasures while fostering a community passionate
                  about sharing stories.
                </p>
                <div className="search-box">
                  <Button as={Link} to="/books/new" className="me-2">
                    Sell
                  </Button>
                  <Button as={Link} to="/exchange">
                    Exchange
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div>
                <img src={BannerImage} className="img-fluid" alt="bannerimage" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={BannerEllipse}
        className="img-fluid banner-elipse"
        alt="arrow"
      />
      <img
        src={BannerArrow}
        className="img-fluid bannerleftarrow"
        alt="arrow"
      />
    </section>
  );
};

export default Banner;
