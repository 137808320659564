import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import { RiArrowRightSLine } from "react-icons/ri";
import { RiArrowLeftSLine } from "react-icons/ri";

import Card from 'pages/Books/components/Card'
import { Row } from "react-bootstrap";




const Trending = ({books=[]}) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: books.length > 4,
    lazyLoad: true,
    speed: 1000,
    slidesToShow: books.length > 4 ? 4 : books.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const slider = useRef();
  return (
    <section className="featured-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 aos aos-init aos-animate" data-aos="fade-up">
            <div className="section-heading">
              <h2>
                Tren<span className="title-right magentaCircle">ding</span> Books
              </h2>
              <p>See what's popular right now</p>
            </div>
          </div>
          <div className="col-md-6 text-md-end aos" data-aos="fade-up">
            <div className="owl-nav mynav2">
              <button
                type="button"
                role="presentation"
                className="owl-prev"
                onClick={() => {console.log(slider?.current); slider?.current?.slickPrev()}}
                
              >
               <RiArrowLeftSLine size={28} style={{margin: '0 auto'}}/>
              </button>
              <button
                type="button"
                role="presentation"
                className="owl-next"
                onClick={() => slider?.current?.slickNext()}
              >
                <RiArrowRightSLine size={28} style={{margin: '0 auto'}}/>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div >
            <Row>
              <Slider ref={slider} {...settings} className="featured-slider grid-view">
                {books.map((book) => (
                  <div className="col-lg-4 col-md-4 col-sm-6" key={book._id}>
                  <Card book={book} />
                  </div>
                ))}
              </Slider>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Trending;