import { useState , useEffect} from 'react';
import Router from './Router'
import AuthContext from "contexts/AuthContext";
import { ToastContainer , toast } from 'react-toastify';
import { getToken } from 'utils/storage';
import { getCurrentUserAPI} from "pages/Users/api"
import Loader from 'components/Loader';
function App() {
  const[ auth, setAuth] =useState({
    isLoggedIn: false,
    user: null,
  });
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    checkAuth()
  },[])


  const checkAuth = () => {
    const token = getToken();
    if(!token) return setIsLoading(false)
    fetchCurrenUser()
  }

  const fetchCurrenUser = async() => {
    try {

      const response = await getCurrentUserAPI()
      setAuth({
        isLoggedIn: true,
        user: response.user
      })
      
    } catch (error) {
      
    } finally {
      setIsLoading(false)
    }
  }

  if(isLoading){
    return <Loader isLoading={true}/>
  }



  return (
    <div className="App">
      <ToastContainer />
      <AuthContext.Provider value={{ auth, setAuth }}>
      <Router />
      </ AuthContext.Provider>
    </div>
  );
}

export default App;