import { useContext } from "react"
import AuthContext from "contexts/AuthContext";

const useAuth = () => {
    const {auth,setAuth } = useContext(AuthContext);
    return {
        isLoggedIn: auth.isLoggedIn,
        user: auth.user,
        setAuth,
    };

};
export default useAuth;