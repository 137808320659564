import { Row, Col } from 'react-bootstrap'
import BookForm from "./components/BookForm";
import { createBookAPI } from './api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const book = {
        name: '',
        author: '',
        publisher: '',
        coverType: '',
        condition: '',
        actualPrice: '',
        sellingPrice: '',
        description: '',
        genre: '',
        preferredType: '',
}

const NewBook = () => {
    const navigate = useNavigate()
    const onSubmitBook = async(book) => {
        try {
            const response = await createBookAPI(book)
            if(response.success){
                navigate("/")
                toast.success("Book created successfully!")
            }else{
                toast.error("Failed to save the book, please try again!")
            }
        } catch (error) {
            toast.error("Failed to save the book, please try again!")
        }
    }
    return (
        <>
        <div className="breadcrumb-bar">
        <div className="container">
            <div className="row align-items-center text-center">
                <div className="col-md-12 col-12">
                    <h2 className="breadcrumb-title">Add a book</h2>
                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                New Book
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div className="dashboard-content">
                <div className="container">
                <BookForm book={book} onSubmit={onSubmitBook}/>
                    </div>
                </div>
        </>
       
    )
}

export default NewBook; 